<template>
  <v-dialog
    v-model="dialog"
    width="900"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon color="blue darken-2">mdi-account</v-icon>
        Add Group Supports
      </v-card-title>
      <v-card-text class="text-h6 font-weight-light">
        <DraggableColumns
          v-if="masterSupports"
          :staticEdit="true"
          :settings="settings"
          :activeColumn="activeSupports"
          :masterColumn="masterSupports"

          :loading="loading"

          @save="saveSupports"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import DraggableColumns from '@/components/elements/DraggableColumns'

export default {
  components: {
    DraggableColumns
  },
  props: {
    group: {
      type: Number,
      required: false,
      default: -1
    },
  },
  data() {
    return {
      dialog: true,
      settings: {
        item: 'Support',
        arrayKey: 'support_id',
        arrayTitle: 'name',
        arrayCategory: 'category',
        arrayDescription: 'description',
        columnOneTitle: 'Plan Supports',
        columnTwoTitle: 'Add Supports',
        emptyStringView: 'There are no supports on this plan.',
        emptyStringEdit: 'There are no trainings currently selected. You can add some from the right column.'
      },
      activeSupports: false,
      masterSupports: false,
      loading: false,
    }
  },
  async created () {
    this.refreshData()
  },

  methods: {   
    async refreshData () {
      this.activeSupports = []
      this.masterSupports = await Api.Supports.getAll()
    },

    async saveSupports (arrayOne) {
      this.loading = true
      this.activeSupports = arrayOne

      const response = await Api.Supports.updateToGroup(this.group, this.activeSupports)
      this.loading = false
      if (response) {
        this.$toast.success('The group supports were successfully recorded.')
        this.$emit('close')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
      return
    }  
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.v-card__title span {
  margin-left: 5px;
}
</style>