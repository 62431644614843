var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-h5"},[_vm._v("Plan Trainings")]),_c('div',{staticClass:"single-grid"},[_c('div',{staticClass:"grid-item"},[(_vm.activeEditing.length===0)?_c('div',{staticClass:"empty-to px-4",attrs:{"move":false}},[_c('p',{staticClass:"text-body-1 mb-0"},[_vm._v(_vm._s(_vm.staticEmptyString || 'There are no trainings on this plan. You can add some from the right column.'))])]):_vm._e()]),_c('div',{staticClass:"grid-item"},[_c('draggable',_vm._b({staticClass:"column column_1",attrs:{"group":{ 
              name: 'name',
              pull: false,
              put: true
            }},on:{"change":_vm.listChange},model:{value:(_vm.activeEditing),callback:function ($$v) {_vm.activeEditing=$$v},expression:"activeEditing"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.activeEditing),function(item,i){return _c('div',{key:i,staticClass:"draggable draggable-active py-3 px-6 mb-2 orange lighten-5"},[_c('div',{staticClass:"more-info",staticStyle:{"flex-grow":"2"}},[_c('span',[_vm._v(_vm._s(item.name)+_vm._s(item.other_name?': '+item.other_name:''))]),(item.date!==undefined)?_c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-caption"},[_c('b',[_vm._v("Date:")]),_vm._v(" "+_vm._s(item.date))])]),_c('v-col',{attrs:{"cols":"6"}},[(item.noc!=='')?_c('span',{staticClass:"text-caption"},[_c('b',[_vm._v("NOC:")]),_vm._v(" "+_vm._s(item.noc))]):_vm._e()])],1)],1):_vm._e()],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.removeAt(i)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1)}),0)],1)]),_c('FormActionButtons',{attrs:{"buttons":[
          _vm.staticEdit ? undefined : 'cancel',
          'save'
        ],"loading":_vm.loading},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.save}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"master_holder"},[_c('p',{staticClass:"text-h5"},[_vm._v("Add Trainings")]),_c('div',{staticClass:"single-grid"},[_c('div',{staticClass:"grid-item"},[_c('v-text-field',{attrs:{"type":"text","label":"Search","outlined":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.searchArray[0].values.length===0)?_c('div',[_c('p',{staticClass:"placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2"},[_vm._v(" There are no search results. ")])]):_vm._e(),(_vm.searchArray[0].values.length!==0)?_c('div',{staticClass:"scroll-area",style:(("max-height:" + _vm.columnHeight + ";"))},[_c('span',{attrs:{"id":"header_top"}}),_vm._l((_vm.searchArray),function(item){return _c('div',{key:item.key},[(_vm.categories.includes(item.key))?_c('span',{staticClass:"text-overline",attrs:{"id":("header_" + (item.key))}},[_vm._v(_vm._s(item.key))]):_vm._e(),_c('draggable',_vm._b({staticClass:"column column_1",attrs:{"group":{ 
                    name: 'name',
                    pull: 'clone',
                    put: false
                  }},model:{value:(item.values),callback:function ($$v) {_vm.$set(item, "values", $$v)},expression:"item.values"}},'draggable',Object.assign({}, _vm.dragOptions,{sort:false,filter:'.no-move'}),false),_vm._l((item.values),function(subitem){return _c('div',{key:subitem.training_id,staticClass:"draggable draggable-active py-3 px-6 mb-2 orange lighten-5",attrs:{"color":"blue lighten-5"}},[_vm._v(" "+_vm._s(subitem.name)+" "),(subitem.description)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem.description))])]):_vm._e()],1)}),0)],1)})],2):_vm._e()],1)])])])],1),(_vm.modal)?_c('AddingModal',{attrs:{"trainingItem":_vm.modalItem},on:{"close":_vm.modalCancel,"update":_vm.modalConfirm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }