<template>
  <v-dialog
    v-model="dialog"
    width="900"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon color="blue darken-2">mdi-account</v-icon>
        Add Group Supports
      </v-card-title>
      <v-card-text class="text-h6 font-weight-light">
        <TrainingColumns
          v-if="masterTrainings"
          :staticEdit="true"
          emptyString="There are no trainings currently selected. You can add some from the right column."
          :activeColumn="activeTrainings"
          :masterColumn="masterTrainings"

          :loading="loading"

          @save="saveTrainings"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import TrainingColumns from '@/components/Plans/elements/TrainingColumns'

export default {
  components: {
    TrainingColumns
  },
  props: {
    group: {
      type: Number,
      required: false,
      default: -1
    },
  },
  data() {
    return {
      dialog: true,
      activeTrainings: false,
      masterTrainings: false,
      loading: false,
    }
  },
  async created () {
    this.refreshData()
  },

  methods: {   
    async refreshData () {
      this.activeTrainings = []
      this.masterTrainings = await Api.Trainings.getAll()
    },

    async saveTrainings (arrayOne) {
      this.loading = true
      this.activeSupports = arrayOne
      const response = await Api.Trainings.updateToGroup(this.group, this.activeSupports)
      this.loading = false
      if (response) {
        this.$toast.success('The group trainings were successfully recorded.')
        this.$emit('close')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
      return
    }  
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.v-card__title span {
  margin-left: 5px;
}
</style>