import connection from '../connection'

// get all interactions

export default {

  async getAll () {
    try {
      const response = await connection.get('/interactions')
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  // async getAllFromClient (clientId) {
  //   try {
  //     const response = await connection.get(`/clients/${clientId}/interactions`)
  //     if (!response) return false
  //     return response.data
  //   } catch (err) {
  //     console.error('Fail. ' + err.message)
  //     return false
  //   }
  // },

  async getSumFromClient (clientId) {
    try {
      const response = await connection.get(`/clients/${clientId}/interactions`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToClient (clientId, interactionsData) {
    try {
      const response = await connection.put(`/clients/${clientId}/interactions`, interactionsData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToGroup (groupId, interactionsData) {
    try {
      const response = await connection.post(`/groups/${groupId}/bulk-create/interaction`, interactionsData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

