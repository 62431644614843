<template>
  <div>
    <div class="skeleton_loader" v-if="!group">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <div v-if="group">
      <div style="text-align:right">
        <FormActionButtons
          :buttons="[]"
          :customButton="{
            text: false,
            color: 'primary',
            label: 'Manage Members'
          }"
          noPadding
          @custom="$emit('edit')"
        />
      </div>
      <v-data-table
        :headers="[
          { text: 'Name', value: 'full_name' },
          { text: 'Date of Birth', value: 'date_of_birth' },
        ]"
        :items="group.clients"
        sort-by="full_name"
        sort-desc
      >
        <template v-slot:[`item.full_name`]="{ item }">
          <router-link :to="{name:'ClientProfilePage',params:{clientId:item.client_id}}">{{item.full_name}}</router-link>
        </template>
        <template v-slot:[`item.date_of_birth`]="{ item }">
          {{ moment(item.date_of_birth).format('MMMM D, YYYY') }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  components: {
    FormActionButtons
  },
  props: {
    group: {
      required: true
    }
  },
  data () {
    return {
      moment: moment,
    }
  },

  created () {
  },

  methods: {
  },

  computed: {
  }
}
</script>

<style lang="scss">
</style>
