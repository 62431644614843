import connection from '../connection'
import store from '../../store'

function sendCleanTrainings (data) {
  let cleaned = {}
  cleaned.name = data.name
  cleaned.description = data.description
  cleaned.category = '-'
  cleaned.certificate = data.certificate || false
  cleaned.noc = false
  cleaned.disabled = data.disabled || false
  cleaned.reporting_type = data.reporting_type

  // convert all empty strings to nulls
  cleaned = Object.keys(cleaned).reduce((acc, key) => { acc[key] = cleaned[key] === '' ? null : cleaned[key]; return acc }, {})

  return cleaned
}

export default {

  async getAll () {
    try {
      const cache = store.getters.getTrainings
      if (cache && cache.data) {
        return cache.data
      }

      // reset cache
      const response = await connection.get('/trainings')
      if (!response) return false
      const sorted = response.data.sort((a, b) => {
        if (a.name === 'Other') return 1
        if (b.name === 'Other') return -1
        if (a.name === b.name) return 0
        return a.name < b.name ? -1 : 1
      })
      store.dispatch('setTrainings', sorted)
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getAllDisabled () {
    try {
      // reset cache
      const response = await connection.get('/trainings?disabled=true')
      if (!response) return false
      const sorted = response.data.sort((a, b) => {
        if (a.name === 'Other') return 1
        if (b.name === 'Other') return -1
        if (a.name === b.name) return 0
        return a.name < b.name ? -1 : 1
      })
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (trainingId) {
    try {
      const response = await connection.get(`/trainings/${trainingId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (trainingId, trainingData) {
    try {
      trainingData = sendCleanTrainings(trainingData)
      const response = await connection.put(`/trainings/${trainingId}`, trainingData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (trainingData) {
    try {
      trainingData = sendCleanTrainings(trainingData)
      const response = await connection.post('/trainings/', trainingData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (trainingId, trainingData) {
    try {
      trainingData = sendCleanTrainings(trainingData)
      trainingData.disabled = true
      const response = await connection.put('/trainings/' + trainingId, trainingData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async restore (trainingId, trainingData) {
    try {
      trainingData = sendCleanTrainings(trainingData)
      trainingData.disabled = false
      const response = await connection.put('/trainings/' + trainingId, trainingData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/trainings`)
      if (!response) return false

      const cleaned = response.data.map((row) => {
        return {
          ...row.training,
          plan_training_id: row.plan_training_id,
          noc: row.noc,
          date: row.date,
          other_name: row.other_name
        }
      })

      return cleaned.sort((a, b) => (a.name < b.name) ? -1 : 1)
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToPlan (planId, trainings) {
    try {
      const cleanedTrainings = trainings.map((row) => {
        return {
          training_id: row.training_id,
          noc: row.noc,
          date: row.date,
          other_name: row.other_name
        }
      })
      const response = await connection.put(`/plans/${planId}/trainings`, cleanedTrainings)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToGroup (groupId, trainings) {
    try {
      const cleanedTrainings = trainings.map((row) => {
        return {
          training_id: row.training_id,
          noc: row.noc,
          date: row.date,
          other_name: row.other_name
        }
      })
      const response = await connection.post(`/groups/${groupId}/bulk-create/training`, cleanedTrainings)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

