<template>
  <div>
    <div class="skeleton_loader" v-if="!users">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <div v-if="users">
      <v-data-table
        id="UsersTable"
        :headers="headers"
        :items="users"
        sort-by="full_name"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-avatar
            :color="item.icon_bg" 
            size="40"
          >
            <span class="white--text text-h6">{{item.icon}}</span>
          </v-avatar>
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          <router-link :to="{name:'UserProfileViewPage',params:{userId:item.user_id}}">{{item.full_name}}</router-link>
        </template>
        <template v-slot:[`item.centre`]="{ item }">
          <router-link v-if="item.centre.centre_id>=0" :to="{name:'CentreProfileViewPage',params:{centreId:item.centre.centre_id}}">{{item.centre.name}}</router-link>
          <div v-if="item.centre.centre_id===-1">{{item.centre.name}}</div>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <a :href="'mailto:'+item.email"><v-icon color="primary" small>mdi-email-outline</v-icon> {{item.email}}</a>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersTable',
  props: {
    users: {
      required: true
    }
  },
  data() {
    return {
    }
  },

  computed: {
    headers () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md': 
          return [
            { text: '', value: 'icon', sortable: false, width: 50 },
            { text: 'Name', value: 'full_name' },
            { text: 'Centre', value: 'centre.name' },
            { text: 'Role', value: 'roles[0].title' }
          ]
        case 'lg':
        case 'xl': 
          return [
            { text: '', value: 'icon', sortable: false, width: 50 },
            { text: 'Name', value: 'full_name' },
            { text: 'Email', value: 'email' },
            { text: 'Centre', value: 'centre.name' },
            { text: 'Role', value: 'roles[0].title' }
          ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
