import connection from '../connection'
import store from '../../store'

function sendCleanSupports (data) {
  let cleaned = {}
  cleaned.name = data.name
  cleaned.description = data.description
  cleaned.category = data.category
  cleaned.disabled = data.disabled || false
  cleaned.reporting_type = data.reporting_type

  // convert all empty strings to nulls
  cleaned = Object.keys(cleaned).reduce((acc, key) => { acc[key] = cleaned[key] === '' ? null : cleaned[key]; return acc }, {})

  return cleaned
}

export default {

  async getAll () {
    try {
      const cache = store.getters.getSupports
      if (cache && cache.data) {
        return cache.data
      }

      // reset cache
      const response = await connection.get('/supports')
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      store.dispatch('setSupports', sorted)
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getAllDisabled () {
    try {
      const response = await connection.get('/supports?disabled=true')
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (supportId) {
    try {
      const response = await connection.get(`/supports/${supportId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (supportId, supportData) {
    try {
      supportData = sendCleanSupports(supportData)
      const response = await connection.put(`/supports/${supportId}`, supportData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (supportData) {
    try {
      supportData = sendCleanSupports(supportData)
      const response = await connection.post('/supports/', supportData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (supportId, supportData) {
    try {
      supportData = sendCleanSupports(supportData)
      supportData.disabled = true
      const response = await connection.put('/supports/' + supportId, supportData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async restore (supportId, supportData) {
    try {
      supportData = sendCleanSupports(supportData)
      supportData.disabled = false
      const response = await connection.put('/supports/' + supportId, supportData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/supports`)
      if (!response) return false

      const cleaned = response.data.map((row) => {
        return {
          ...row.support,
          other_name: row.other_name
        }
      })

      // remove duplicates
      const tempCleaned = []
      if (!cleaned) return tempCleaned

      cleaned.forEach((item) => {
        // check if item.support_id is already in array
        if (tempCleaned.find((x) => x.support_id === item.support_id) && item.name !== 'Other') {
          return
        }
        tempCleaned.push(item)
        return true
      })

      return tempCleaned

    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToPlan (planId, supports) {
    try {
      const cleanedSupports = supports.map((row) => {
        return {
          support_id: row.support_id,
          other_name: row.other_name
        }
      })
      const response = await connection.put(`/plans/${planId}/supports`, cleanedSupports)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToGroup (groupId, supports) {
    try {
      const cleanedSupports = supports.map((row) => {
        return {
          support_id: row.support_id,
          other_name: row.other_name
        }
      })
      const response = await connection.post(`/groups/${groupId}/bulk-create/support`, cleanedSupports)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

