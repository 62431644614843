<template>
  <v-container class="pa-0">
    <v-row>

      <v-col cols="5">
        <p class="text-h5">{{settings.columnOneTitle}}</p>
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to px-4" v-if="activeEditing.length===0" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2" v-html="settings.emptyStringEdit"></p>
            </div>
          </div>
          <div class="grid-item">
            <draggable
              class="column column_1"
              v-model="activeEditing"
              v-bind="dragOptions" 
              :group="{ 
                name: settings.arrayTitle,
                pull: false,
                put: true
              }"
              @change="updateMasterList"
            >
              <div 
                class="draggable draggable-active py-3 px-6 mb-2 orange lighten-5"
                v-for="(item, i) in activeEditing" 
                :key="i"
              >
                {{item[settings.arrayTitle]}}{{ item.other_name?': '+item.other_name:'' }}
                <v-btn 
                  small 
                  icon
                  @click="removeAt(i)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
            </draggable>
          </div>
        </div>

        <FormActionButtons
          :buttons="[
            staticEdit ? undefined : 'cancel',
            'save'
          ]" 
          :loading="loading"
          @cancel="$emit('cancel')"
          @save="save"
        />

      </v-col>
      <v-col cols="7">
        <div class="master_holder">
          <p class="text-h5" v-if="settings.columnTwoTitle">{{settings.columnTwoTitle}}</p> 
          <div class="single-grid">
            <div class="grid-item">

              <v-text-field
                type="text"
                v-model="searchString"
                label="Search"
                outlined
              />

              <div v-if="masterEditing.length===0">
                <p class="placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2">
                  There are no search results.
                </p>
              </div>
              <div v-if="masterEditing.length!==0">
                <v-tabs class="mb-3" show-arrows>
                  <v-tab
                    :class="(category===''?'hidden':'')"
                    v-for="(category) in categories" 
                    :key="category"
                    @click="$vuetify.goTo(`#header_${category.replace(/(\s|[.])+/g,'-')}`,{container:'.scroll-area'})"
                  >{{category}}</v-tab>
                  <v-tab @click="$vuetify.goTo(`#header_other`,{container:'.scroll-area'})">Other</v-tab>
                </v-tabs>

                <div class="scroll-area" :style="`max-height:${columnHeight};`">
                  <div v-for="item in masterEditing" :key="item.key">
                    <span :id="`header_${item.key.replace(/(\s|[.])+/g,'-')}`" class="text-overline" v-if="categories.includes(item.key)">{{item.key}}</span>
                    <span :id="`header_other`" class="text-overline" v-if="item.key===''">Other</span>
                    <draggable 
                      class="column column_1"
                      v-model="item.values"
                      v-bind="{...dragOptions,sort:false,filter:'.no-move'}" 
                      :group="{
                        name: settings.arrayTitle,
                        pull: (item.key===''?'clone':true),
                        put: false
                      }"
                      @change="updateMasterList"
                    >
                      <div 
                        class="draggable draggable-active py-3 px-6 mb-2 orange lighten-5"
                        color="blue lighten-5"
                        v-for="subitem in item.values"
                        :key="subitem[settings.arrayKey]"
                      >
                        {{subitem[settings.arrayTitle]}}

                        <v-tooltip left v-if="subitem[settings.arrayDescription]">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="help-icon"
                            >mdi-information-outline</v-icon>
                          </template>
                          <span>{{subitem[settings.arrayDescription]}}</span>
                        </v-tooltip>
                      </div>
                    </draggable>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </v-col>

    </v-row>

    <!-- MODAL -->
    <v-dialog
      v-model="modal.show"
      width="500"
      @click:outside="modalCancel"
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-medium">
          <v-icon color="blue darken-2">mdi-information-outline</v-icon>
          Information Required
        </v-card-title>

        <v-card-text class="text-h6 font-weight-light">
          <v-form 
            ref="form"
            @submit.prevent="modalConfirm" 
            novalidate
          >
            <p>Please give this {{settings.item.toLowerCase()}} a name.</p>
            <v-text-field
              label="Name *"
              type="text"
              outlined
              v-model="modal.other_name"
              :rules="[
                rules.required
              ]"
            />
          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="modalCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="modalConfirm"
          >
            Add {{settings.item}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import validationRules from '@/helpers/validationRules'

import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    draggable,
    FormActionButtons
  },
  props: {
    staticEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    columnHeight: {
      type: String,
      required: false,
      default: '600px'
    },
    settings: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    activeColumn: {
      required: true
    },
    masterColumn: {
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      rules: false,
      activeEditing: [],
      cleanedMaster: [],
      masterEditing: [],
      searchString: '',
      modal: {
        show: false,
        newIndex: false,
        other_name: ''
      }
    }
  },
  async created () {
    this.rules = await validationRules
    this.resetActiveList()
    this.updateMasterList()
  },
  methods: {
    resetActiveList () {
      this.activeEditing = [...this.activeColumn]
    },
    updateMasterList (event=false) {
      if (event.added) this.listChange(event)
      this.cleanedMaster = []
      this.searchedMaster.forEach((objMaster) => {
        let result = true

        this.activeEditing.forEach((objActive) => {
          if (objMaster[this.settings.arrayTitle]==='Other') {
            result = true
            return true
          }

          if (objMaster[this.settings.arrayKey] === objActive[this.settings.arrayKey]) {
            result = false
            return true
          }
        })

        if (result) this.cleanedMaster.push(objMaster)
      })

      this.masterEditing = []
      this.categories.forEach((item, i) => {
        this.masterEditing.push({key: item, values: this.cleanedMaster.filter((obj) => obj.category === item)})
      })
    },
    async removeAt(index) {
      await this.activeEditing.splice(index, 1)
      this.updateMasterList()
    },

    listChange (event) {
      event = event.added
      if (event.element[this.settings.arrayTitle]==='Other') {
        this.modal = {
          newIndex: event.newIndex,
          show: true,
          other_name: '',
        }
      }
    },
    async modalCancel () {
      await this.activeEditing.splice(this.modal.newIndex, 1)
      this.updateMasterList()
      this.modal.show = false
    },
    modalConfirm () {
      // check values
      if( !this.$refs.form.validate() ) return

      this.activeEditing[this.modal.newIndex] = {
        ...this.activeEditing[this.modal.newIndex],
        other_name: this.modal.other_name
      }
      this.modal.show = false
    },

    save () {
      this.$emit('save', this.activeEditing)
    },
    
    searchArray(array){
      if (array.length === 0) return []
      const searchString = this.searchString.toLowerCase()
      let searched = []
      array.forEach((item,i) => {
        searched[i] = {
          key: item.key, 
          values: item.values.filter(function (e) {
            return e.name.toLowerCase().includes(searchString)
          })
        }
      })
      return searched
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    categories () {
      return [...new Set(this.cleanedMaster.map((obj) => obj.category))].sort((a, b) => {
        if (a === '' || a === null) return 1
        if (b === '' || b === null) return -1
        if (a === b) return 0
        return a < b ? -1 : 1
      })
    },
    searchedMaster () {
      if (this.masterColumn.length === 0) return []
      const searchString = this.searchString.toLowerCase()
      const searched = this.masterColumn.filter(function (el) {
        return el.name.toLowerCase().includes(searchString)
      })
      return searched
    }
  },
  watch: {
    searchString (newValue, oldvalue) {
      this.updateMasterList()
    }
  }
}
</script>

<style scoped lang="scss">
// * {
//   border: 1px solid green;
// }
.column {
  height: 100%;
}

.master_holder {
  border: 1px solid #E0E0E0;
  padding: 28px 30px;
  border-radius: 0.25rem;
}

.single-grid {
  display: grid;
  grid-template-columns: 100%; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  // height: 100%;
  min-height: 150px;

  .grid-item {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.scroll-area {
  overflow-y: scroll;
  padding-right: 10px;
}

.draggable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 0.25rem;

  &.draggable-active {
    &:hover {
      cursor: grab;
      background-color: #FF9800!important;
      color: white;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.empty-to {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  height: 150px;
  opacity: 0.8;
  border: 2px dashed #BDBDBD;
  border-radius: 0.25rem;
  text-align: center;
}
</style>
