var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.settings.columnOneTitle))]),_c('div',{staticClass:"single-grid"},[_c('div',{staticClass:"grid-item"},[(_vm.activeEditing.length===0)?_c('div',{staticClass:"empty-to px-4",attrs:{"move":false}},[_c('p',{staticClass:"placeholder text-body-1 mb-0 blue-grey--text text--darken-2",domProps:{"innerHTML":_vm._s(_vm.settings.emptyStringEdit)}})]):_vm._e()]),_c('div',{staticClass:"grid-item"},[_c('draggable',_vm._b({staticClass:"column column_1",attrs:{"group":{ 
              name: _vm.settings.arrayTitle,
              pull: false,
              put: true
            }},on:{"change":_vm.updateMasterList},model:{value:(_vm.activeEditing),callback:function ($$v) {_vm.activeEditing=$$v},expression:"activeEditing"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.activeEditing),function(item,i){return _c('div',{key:i,staticClass:"draggable draggable-active py-3 px-6 mb-2 orange lighten-5"},[_vm._v(" "+_vm._s(item[_vm.settings.arrayTitle])+_vm._s(item.other_name?': '+item.other_name:'')+" "),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.removeAt(i)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1)}),0)],1)]),_c('FormActionButtons',{attrs:{"buttons":[
          _vm.staticEdit ? undefined : 'cancel',
          'save'
        ],"loading":_vm.loading},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.save}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"master_holder"},[(_vm.settings.columnTwoTitle)?_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.settings.columnTwoTitle))]):_vm._e(),_c('div',{staticClass:"single-grid"},[_c('div',{staticClass:"grid-item"},[_c('v-text-field',{attrs:{"type":"text","label":"Search","outlined":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.masterEditing.length===0)?_c('div',[_c('p',{staticClass:"placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2"},[_vm._v(" There are no search results. ")])]):_vm._e(),(_vm.masterEditing.length!==0)?_c('div',[_c('v-tabs',{staticClass:"mb-3",attrs:{"show-arrows":""}},[_vm._l((_vm.categories),function(category){return _c('v-tab',{key:category,class:(category===''?'hidden':''),on:{"click":function($event){_vm.$vuetify.goTo(("#header_" + (category.replace(/(\s|[.])+/g,'-'))),{container:'.scroll-area'})}}},[_vm._v(_vm._s(category))])}),_c('v-tab',{on:{"click":function($event){return _vm.$vuetify.goTo("#header_other",{container:'.scroll-area'})}}},[_vm._v("Other")])],2),_c('div',{staticClass:"scroll-area",style:(("max-height:" + _vm.columnHeight + ";"))},_vm._l((_vm.masterEditing),function(item){return _c('div',{key:item.key},[(_vm.categories.includes(item.key))?_c('span',{staticClass:"text-overline",attrs:{"id":("header_" + (item.key.replace(/(\s|[.])+/g,'-')))}},[_vm._v(_vm._s(item.key))]):_vm._e(),(item.key==='')?_c('span',{staticClass:"text-overline",attrs:{"id":"header_other"}},[_vm._v("Other")]):_vm._e(),_c('draggable',_vm._b({staticClass:"column column_1",attrs:{"group":{
                      name: _vm.settings.arrayTitle,
                      pull: (item.key===''?'clone':true),
                      put: false
                    }},on:{"change":_vm.updateMasterList},model:{value:(item.values),callback:function ($$v) {_vm.$set(item, "values", $$v)},expression:"item.values"}},'draggable',Object.assign({}, _vm.dragOptions,{sort:false,filter:'.no-move'}),false),_vm._l((item.values),function(subitem){return _c('div',{key:subitem[_vm.settings.arrayKey],staticClass:"draggable draggable-active py-3 px-6 mb-2 orange lighten-5",attrs:{"color":"blue lighten-5"}},[_vm._v(" "+_vm._s(subitem[_vm.settings.arrayTitle])+" "),(subitem[_vm.settings.arrayDescription])?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem[_vm.settings.arrayDescription]))])]):_vm._e()],1)}),0)],1)}),0)],1):_vm._e()],1)])])])],1),_c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":_vm.modalCancel},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-medium"},[_c('v-icon',{attrs:{"color":"blue darken-2"}},[_vm._v("mdi-information-outline")]),_vm._v(" Information Required ")],1),_c('v-card-text',{staticClass:"text-h6 font-weight-light"},[_c('v-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.modalConfirm.apply(null, arguments)}}},[_c('p',[_vm._v("Please give this "+_vm._s(_vm.settings.item.toLowerCase())+" a name.")]),_c('v-text-field',{attrs:{"label":"Name *","type":"text","outlined":"","rules":[
              _vm.rules.required
            ]},model:{value:(_vm.modal.other_name),callback:function ($$v) {_vm.$set(_vm.modal, "other_name", $$v)},expression:"modal.other_name"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.modalCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.modalConfirm}},[_vm._v(" Add "+_vm._s(_vm.settings.item)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }