var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.users)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.users)?_c('div',[_c('v-data-table',{attrs:{"id":"UsersTable","headers":_vm.headers,"items":_vm.users,"sort-by":"full_name"},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.icon_bg,"size":"40"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(item.icon))])])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'UserProfileViewPage',params:{userId:item.user_id}}}},[_vm._v(_vm._s(item.full_name))])]}},{key:"item.centre",fn:function(ref){
var item = ref.item;
return [(item.centre.centre_id>=0)?_c('router-link',{attrs:{"to":{name:'CentreProfileViewPage',params:{centreId:item.centre.centre_id}}}},[_vm._v(_vm._s(item.centre.name))]):_vm._e(),(item.centre.centre_id===-1)?_c('div',[_vm._v(_vm._s(item.centre.name))]):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:'+item.email}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-email-outline")]),_vm._v(" "+_vm._s(item.email))],1)]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }