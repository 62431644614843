<template>
  <div 
    class="FormActionButtons"
    :class="[{noPadding}, {noTopPadding}]"
  >

    <!-- Cancel -->
    <v-btn 
      v-if="buttons.includes('cancel')"
      class="white--text"
      color="grey"
      elevation="0"
      :min-width="minWidth"
      :disabled="loading"
      @click="$emit('cancel')"
    >
      <v-icon small class="pr-1">mdi-close</v-icon> Cancel 
    </v-btn>
    
    <!-- Back -->
    <v-btn
      v-if="buttons.includes('back')"
      class="white--text"
      color="grey"
      elevation="0"
      :min-width="minWidth"
      :disabled="loading"
      @click="$emit('back')"
    >
      <v-icon small class="pr-1">mdi-chevron-left</v-icon> Back
    </v-btn>
    
    <!-- Next -->
    <v-btn
      v-if="buttons.includes('next')"
      color="primary"
      elevation="0"
      :min-width="minWidth"
      :disabled="loading"
      @click="$emit('next')"
    >
      Next <v-icon small class="pl-1">mdi-chevron-right</v-icon>
    </v-btn>
    
    <!-- Edit -->
    <v-btn 
      v-if="buttons.includes('edit')"
      color="primary"
      elevation="0"
      :min-width="minWidth"
      :disabled="loading"
      @click="$emit('edit')"
    >
      Edit <v-icon small class="pl-1">mdi-pencil</v-icon>
    </v-btn>

    <!-- Save -->
    <v-btn 
      v-if="buttons.includes('save')"
      color="success"
      elevation="0"
      :min-width="minWidth"
      :loading="loading"
      :disabled="loading"
      @click="$emit('save')"
    >
      Save <v-icon small class="pl-1">mdi-content-save</v-icon>
    </v-btn>
    
    <!-- Delete -->
    <v-btn
      v-if="buttons.includes('delete')"
      color="red darken-2"
      elevation="0"
      text
      :min-width="minWidth"
      :loading="loading"
      :disabled="loading"
      @click="$emit('delete')"
    >
      Delete
    </v-btn>

    <!-- Custom Button -->
    <v-btn
      v-if="customButton"
      elevation="0"
      :color="customButton.color"
      :text="customButton.text"
      :min-width="customButton.minWidth || minWidth"
      :loading="loading"
      :disabled="loading"
      @click="$emit('custom')"
    >
      <v-icon small class="pr-1" v-if="customButton.icon_start">{{customButton.icon}}</v-icon> {{customButton.label}} <v-icon small class="pl-1" v-if="!customButton.icon_start">{{customButton.icon}}</v-icon>
    </v-btn>

  </div>
</template>

<script>
export default {
  name: 'FormActionButtons',
  props: {
    buttons: {
      type: Array,
      required: true
    },
    customButton: {
      type: Object,
      required: false
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    },
    noTopPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      minWidth: '150px'
    }
  }
}
</script>

<style scoped lang="scss">
.FormActionButtons {
  margin-top: 1.875rem;
  display: inline-block;
  
  button {
    margin-right: 20px;
  }

  &.noPadding {
    margin: 0!important;
    button {
      margin: 0!important;
    }
  }
  &.noTopPadding {
    margin-top: 0!important;
  }
}
</style>
