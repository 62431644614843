<template>
  <div>
    <div class="skeleton_loader" v-if="!centres">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <div v-if="centres">
      <v-data-table
        id="CentresTable"
        :headers="headers"
        :items="centres"
        sort-by="name"
      >
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="{name:'CentreProfileViewPage',params:{centreId:item.centre_id}}">{{item.name}}</router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CentresTable',
  props: {
    centres: {
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'City', value: 'city' },
        { text: 'Phone', value: 'phone' }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
