<template>
  <v-container class="pa-0">
    <v-row>

      <v-col cols="5">
        <p class="text-h5">Plan Trainings</p>
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to px-4" v-if="activeEditing.length===0" :move="false">
              <p class="text-body-1 mb-0">{{ staticEmptyString || 'There are no trainings on this plan. You can add some from the right column.'}}</p>
            </div>
          </div>
          <div class="grid-item">
            <draggable
              class="column column_1"
              v-model="activeEditing"
              v-bind="dragOptions" 
              :group="{ 
                name: 'name',
                pull: false,
                put: true
              }"
              @change="listChange"
            >
              <div 
                class="draggable draggable-active py-3 px-6 mb-2 orange lighten-5"
                v-for="(item, i) in activeEditing" 
                :key="i"
              >
                <div class="more-info" style="flex-grow:2">
                  <span>{{item.name}}{{item.other_name?': '+item.other_name:''}}</span>
                  <v-container style="padding:0;" v-if="item.date!==undefined">
                    <v-row>
                      <v-col cols="6">
                        <span class="text-caption"><b>Date:</b> {{item.date}}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="text-caption" v-if="item.noc!==''"><b>NOC:</b> {{item.noc}}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <v-btn 
                  small 
                  icon
                  @click="removeAt(i)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>

              </div>
            </draggable>
          </div>
        </div>

        <FormActionButtons
          :buttons="[
            staticEdit ? undefined : 'cancel',
            'save'
          ]" 
          :loading="loading"
          @cancel="$emit('cancel')"
          @save="save"
        />

      </v-col>
      <v-col cols="7">
        <div class="master_holder">
          <p class="text-h5">Add Trainings</p>
          <div class="single-grid">
            <div class="grid-item">

              <v-text-field
                type="text"
                v-model="searchString"
                label="Search"
                outlined
              />

              <!-- <v-tabs class="mb-3" show-arrows>>
                <v-tab @click="$vuetify.goTo(`#header_top`,{container:'.scroll-area'})">All</v-tab>
                <v-tab
                  v-for="category in categories" 
                  :key="category"
                  @click="$vuetify.goTo(`#header_${category}`,{container:'.scroll-area'})"
                >{{category}}</v-tab>
              </v-tabs> -->

              <div v-if="searchArray[0].values.length===0">
                <p class="placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2">
                  There are no search results.
                </p>
              </div>

              <div class="scroll-area" v-if="searchArray[0].values.length!==0" :style="`max-height:${columnHeight};`">
                <span id="header_top"></span>
                <div v-for="item in searchArray" :key="item.key">
                  <span :id="`header_${item.key}`" class="text-overline" v-if="categories.includes(item.key)">{{item.key}}</span>
                  <draggable 
                    class="column column_1"
                    v-model="item.values"
                    v-bind="{...dragOptions,sort:false,filter:'.no-move'}" 
                    :group="{ 
                      name: 'name',
                      pull: 'clone',
                      put: false
                    }"
                  >
                    <div 
                      class="draggable draggable-active py-3 px-6 mb-2 orange lighten-5"
                      color="blue lighten-5"
                      v-for="subitem in item.values"
                      :key="subitem.training_id"
                    >
                      {{subitem.name}}

                      <v-tooltip left v-if="subitem.description">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="help-icon"
                          >mdi-information-outline</v-icon>
                        </template>
                        <span>{{subitem.description}}</span>
                      </v-tooltip>
                    </div>
                  </draggable>
                </div>
              </div>

            </div>
          </div>
        </div>

      </v-col>

    </v-row>
    
    <AddingModal 
      :trainingItem="modalItem"
      v-if="modal"
      @close="modalCancel"
      @update="modalConfirm"
    />

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import validationRules from '@/helpers/validationRules'
import AddingModal from '@/components/Plans/elements/TrainingColumns/AddingModal.vue'

import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    draggable,
    AddingModal,
    FormActionButtons
  },
  props: {
    staticEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    staticEmptyString: {
      type: String,
      required: false,
      default: ''
    },
    columnHeight: {
      type: String,
      required: false,
      default: '600px'
    },
    activeColumn: {
      required: true
    },
    masterColumn: {
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      rules: false,
      activeEditing: [],
      cleanedMaster: [],
      masterEditing: [],
      modal: false,
      modalItem: false,
      newIndex: false,
      searchString: '',
    }
  },
  async created () {
    this.rules = await validationRules
    this.resetActiveList()
    this.updateMasterList()
  },
  methods: {
    resetActiveList () {
      this.activeEditing = [...this.activeColumn]
    },
    updateMasterList () {
      this.masterEditing = [{key:'', values: this.masterColumn}]
      // this.categories.forEach((item, i) => {
      //   this.masterEditing.push({key: item, values: this.masterColumn.filter((obj) => obj.category === item)})
      // })
    },
    async removeAt(index) {
      await this.activeEditing.splice(index, 1)
    },

    listChange (event) {
      if (event.added) {
        event = event.added

        // event.element.noc || event.element.noc==='' ? this.modal.noc_show = true : this.modal.noc_show = false
        this.modalItem = event.element
        this.newIndex = event.newIndex

        this.updateMasterList()

        this.modal = true
      }
    },
    async modalCancel () {
      await this.activeEditing.splice(this.newIndex, 1)
      this.modal = false
    },
    modalConfirm (newItem) {
      this.activeEditing[this.newIndex] = newItem
      this.modal = false
    },

    save () {
      this.$emit('save', this.activeEditing)
    },

    
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    categories () {
      return [...new Set(this.masterColumn.map((obj) => obj.category))].sort()
    },
    searchArray(array){
      if (this.masterEditing.length === 0) return [{values:[]}]
      const searchString = this.searchString.toLowerCase()
      const searched = this.masterEditing[0].values.filter(function (el) {
        return el.name.toLowerCase().includes(searchString)
      })
      return [{key: '', values: searched}]
    }
  }
}
</script>

<style scoped lang="scss">
// * {
//   border: 1px solid green;
// }
.column {
  height: 100%;
}

.master_holder {
  border: 1px solid #E0E0E0;
  padding: 28px 30px;
  border-radius: 0.25rem;
}

.single-grid {
  display: grid;
  grid-template-columns: 100%;  
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  // height: 100%;
  min-height: 150px;

  .grid-item {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.scroll-area {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
}

.draggable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 0.25rem;

  &.draggable-active {
    &:hover {
      cursor: grab;
      background-color: #FF9800!important;
      color: white;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.empty-to {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  height: 150px;
  opacity: 0.8;
  border: 2px dashed #BDBDBD;
  border-radius: 0.25rem;
  text-align: center;
}
</style>
